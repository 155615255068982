<template>
  <v-btn-toggle mandatory multiple dense v-model="filterDivision">
    <v-btn outlined text :key="0">alle</v-btn>
    <v-btn outlined text v-for="division in divisions" :key="division.id">{{
      division.code
    }}</v-btn>
  </v-btn-toggle>
</template>
<script>
export default {
  name: 'DivisionFilter',
  props: {
    value: [],
  },
  data() {
    return {
      filterDivision: [0],
      divisions: [],
    };
  },
  watch: {
    filterDivision(newValue, oldValue) {
      if (newValue.length == 1) {
        window.localStorage.setItem('filterDivision', JSON.stringify(newValue));
        this.$emit('input', newValue);
        return;
      }
      if (oldValue.includes(0) && newValue.includes(0)) {
        this.filterDivision.shift();
        return;
      }
      if (newValue.includes(0)) {
        this.filterDivision = [0];
        return;
      }
      if (newValue.length == 3) {
        this.filterDivision = [0];
        return;
      }
      window.localStorage.setItem('filterDivision', JSON.stringify(newValue));
      this.$emit('input', newValue);
    },
    value() {
      this.filterDivision = this.value;
    },
  },
  async mounted() {
    this.divisions = await this.apiList({ resource: 'common/division' });
    if (localStorage.getItem('filterDivision')) {
      this.filterDivision = JSON.parse(localStorage.getItem('filterDivision'));
    }
  },
};
</script>
